import { animate, style, transition, trigger } from '@angular/animations';
import { NgClass, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  afterNextRender,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Subscription, firstValueFrom } from 'rxjs';
import { User } from '../../../Authentication/models/auth.models';
import { AuthService } from '../../../Authentication/services/auth/auth.service';
import { ProductService } from '../../../Order/services/product/product.service';
import { GlobalSearchResult, ShopListCategory } from '../../models/models';
import { ApiService } from '../../services/api/api.service';
import { UtilityService } from '../../services/utility/utility.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    TitleCasePipe,
    RouterLink,
    RouterLinkActive,
    FormsModule,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
  animations: [
    trigger('fadeAnimation', [
      transition('void => *', [
        style({ opacity: 0, transform: 'translateY(110%)' }),
        animate('300ms', style({ opacity: 1, transform: 'translateY(102%)' })),
      ]),
      transition('* => void', [
        animate('300ms', style({ opacity: 0, transform: 'translateY(110%)' })),
      ]),
    ]),
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('moHeader') stickyHeader: ElementRef;
  @ViewChild('modalCloseBtn') closeModalBtn: ElementRef;

  observer: IntersectionObserver;

  mobileMenuOpen = false;
  mobileShopMenuOpen = false;

  shopList: ShopListCategory[] = [];
  cartProductCount = 0;
  wishlistProductCount = 0;

  isLoggedIn: User | null;
  profileMenuOpen = false;

  searchQuery = '';
  globalSearchResults: GlobalSearchResult[] = [];

  shopMenuSubscription: Subscription;
  userSubscription: Subscription;
  guestCartSubscription: Subscription;
  userCartSubscription: Subscription;
  wishlistSubscription: Subscription;
  routerSubscription: Subscription;
  globalSearchEventSubscription: Subscription;
  globalSearchSubscription: Subscription;

  constructor(
    private auth: AuthService,
    private product: ProductService,
    private api: ApiService,
    private utility: UtilityService,
    private renderer: Renderer2
  ) {
    afterNextRender(() => {
      this.observer = new IntersectionObserver(
        ([e]) => {
          if (e.intersectionRatio < 1) {
            this.renderer.addClass(this.stickyHeader.nativeElement, 'bg-pink');
          } else {
            this.renderer.removeClass(
              this.stickyHeader.nativeElement,
              'bg-pink'
            );
          }
        },
        {
          rootMargin: '-1px 0px 0px 0px',
          threshold: [1],
        }
      );

      this.observer.observe(this.stickyHeader.nativeElement);
    });
  }

  ngOnInit(): void {
    this.getShopMenu();

    this.userSubscription = this.auth.userSubject.subscribe({
      next: (data) => {
        this.isLoggedIn = data;
        if (data) {
          this.product.getUserCart();
          this.product.getUserWishlist();
        } else {
          this.checkCartCount();
        }
      },
    });
    this.guestCartSubscription = this.product.guestCartSubject.subscribe({
      next: () => {
        this.checkCartCount();
      },
    });
    this.userCartSubscription = this.product.userCartSubject.subscribe({
      next: () => {
        this.checkCartCount();
      },
    });
    this.wishlistSubscription = this.product.wishlistSubject.subscribe({
      next: () => {
        this.checkWishlistCount();
      },
    });
    this.routerSubscription = this.utility.routerSubject.subscribe({
      next: () => {
        this.mobileMenuOpen = false;
        this.profileMenuOpen = false;
      },
    });
  }

  getShopMenu() {
    this.shopMenuSubscription = this.api.getShopMenu().subscribe({
      next: (res) => {
        if (res.status === 'success') {
          this.shopList = <ShopListCategory[]>res.data;
        }
      },
      error: (err) => {},
    });
  }

  async checkWishlistCount() {
    this.wishlistProductCount =
      (await firstValueFrom(this.product.wishlistSubject))?.length || 0;
  }

  async checkCartCount() {
    const user = await firstValueFrom(this.auth.userSubject);

    if (user) {
      this.cartProductCount = (
        await firstValueFrom(this.product.userCartSubject)
      ).cartItemsCount;
    } else {
      this.cartProductCount = (
        await firstValueFrom(this.product.guestCartSubject)
      ).cartItemsCount;
    }
  }

  globalSearch() {
    setTimeout(() => {
      this.globalSearchSubscription = this.api
        .globalSearch({ search_input: this.searchQuery })
        .subscribe({
          next: (res) => {
            if (res.status === 'success') {
              this.globalSearchResults = <GlobalSearchResult[]>res.data;
            } else {
              this.globalSearchResults = [];
            }
          },
          error: (err) => {},
        });
    }, 0);
  }

  closeSearchModal() {
    this.searchQuery = '';
    this.closeModalBtn.nativeElement.click();
  }

  ngOnDestroy(): void {
    this.shopMenuSubscription?.unsubscribe();
    this.userSubscription?.unsubscribe();
    this.guestCartSubscription?.unsubscribe();
    this.userCartSubscription?.unsubscribe();
    this.wishlistSubscription?.unsubscribe();
    this.routerSubscription?.unsubscribe();
    this.globalSearchSubscription?.unsubscribe();

    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
