import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { firstValueFrom } from 'rxjs';
import { UtilityService } from '../../../Shared/services/utility/utility.service';

export const authGuard: CanActivateFn = async () => {
  const auth = inject(AuthService);
  const utility = inject(UtilityService);
  const router = inject(Router);

  const user = await firstValueFrom(auth.userSubject);
  if (user) {
    return true;
  } else {
    utility.showErrorMessage('Please login first');
    router.navigate(['/'])
    return false;
  }
};
