import { NgIf, isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  afterNextRender,
} from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from './Authentication/services/auth/auth.service';
import { ProductService } from './Order/services/product/product.service';
import { FooterComponent } from './Shared/components/footer/footer.component';
import { HeaderComponent } from './Shared/components/header/header.component';
import { PreCheckoutModalComponent } from './Shared/components/pre-checkout-modal/pre-checkout-modal.component';
import { UtilityService } from './Shared/services/utility/utility.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [NgIf, RouterOutlet, FooterComponent, HeaderComponent, PreCheckoutModalComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnInit, OnDestroy {
  routerSubscription: Subscription;
  HFvisible = false;

  constructor(
    private auth: AuthService,
    private router: Router,
    private product: ProductService,
    private utility: UtilityService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    afterNextRender(() => {
      this.auth.autoLogin();
      this.product.setGuestCartOnStartup();
    });
  }

  ngOnInit(): void {
    this.routerSubscription = this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          this.utility.routerSubject.next(true);
          this.HFvisible = true;
          if (
            event.url.includes('login') ||
            event.url.includes('register') ||
            event.url.includes('checkout')
          ) {
            this.HFvisible = false;
          }

          if (isPlatformBrowser(this.platformId)) {
            if (event.url.includes('checkout')) {
              this.product.setBuyNowOnStartup();
            } else {
              this.product.clearBuyNow();
            }
          }
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
  }
}
