<div class="modal right fade checkout-modal" id="checkoutModal" tabindex="-1"
 aria-labelledby="checkoutModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">

        <button class="close-btn-login" *ngIf="currentMode !== 'register'" data-bs-dismiss="modal" aria-label="Close" #dismissModalBtn>
          <i class="fa-solid fa-arrow-left fa fa-xl"></i>
        </button>

        <div id="login-head" *ngIf="currentMode === 'login'">
          <h5 class="modal-title" id="checkoutModalLabel">Login</h5>
          <p>for a tailored experience</p>
        </div>
        <div id="guest-login-head" *ngIf="currentMode === 'register'">
          <h5 class="modal-title" id="checkoutModalGuestLabel">
            Proceed as <br />Guest
          </h5>
          <button (click)="currentMode = 'login'" class="go-back">
            <i class="fa-solid fa-arrow-left"></i>
          </button>
        </div>
        <!-- <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          #dismissModalBtn
        >close</button> -->
      </div>
      <div class="modal-body">
        <form class="login-form" *ngIf="currentMode === 'login'">

          <!-- --------------------------------------  -->
           <button class="button-style2 mb-4 text-center clr-pink" type="button" *ngIf="currentMode === 'login'" (click)="currentMode = 'register'">
              Checkout as a Guest
           </button>
          <!-- -------------------------------------- -->
          
          <div class="or-btn">OR <br><span>Registered Customers</span></div>

          <div class="email-login">
            <div class="mb-4">
              <input
                type="email"
                class="form-control"
                id="modalloginPhoneOrEmail"
                name="modalloginPhoneOrEmail"
                placeholder="Enter Phone / Email"
                autofocus
                [(ngModel)]="userPhoneOrEmail"
                [ngClass]="{
                  'input-has-err': loginValidationErrors.nameOrEmailErr
                }"
              />
              <div
                class="form-text"
                *ngIf="loginValidationErrors.nameOrEmailErr"
              >
                {{ loginValidationErrors.nameOrEmailErrMsg }}
              </div>
            </div>
            <div class="mb-2" *ngIf="loginOtpSent">
              <input
                type="number"
                class="form-control"
                id="modalloginOTP"
                name="modalloginOTP"
                placeholder="Enter OTP"
                autocomplete="off"
                [(ngModel)]="loginOtp"
                #loginOtpInput
                [ngClass]="{ 'input-has-err': loginValidationErrors.otplErr }"
              />
              <div class="form-text" *ngIf="loginValidationErrors.otplErr">
                {{ loginValidationErrors.otplErrMsg }}
              </div>
            </div>
            <div class="d-flex justify-content-end mb-4" *ngIf="loginOtpSent">
              <button
                class="resend-timer"
                [disabled]="loginResendTimer !== 0"
                (click)="sendLoginOTP()"
              >
                Resend OTP <span *ngIf="loginResendTimer !== 0">in </span
                ><span class="time" *ngIf="loginResendTimer !== 0"
                  >{{ loginResendTimer }}s</span
                >
              </button>
            </div>
          </div>
          <div class="col-12 text-center">
            <div class="button-wrapper">
              <button
                class="button-style2 mb-3 text-center"
                type="button"
                (click)="sendLoginOTP()"
                *ngIf="!loginOtpSent"
              >
                Send OTP
              </button>
              <button
                class="button-style2 mb-3 text-center"
                type="button"
                (click)="loginUser()"
                *ngIf="loginOtpSent"
              >
                Login
              </button>
            </div>
          </div>
        </form>
        <form class="login-form" *ngIf="currentMode === 'register'">
          <div class="row">
            <div class="col-lg-12">
              <div class="mb-4">
                <input
                  type="text"
                  class="form-control"
                  id="registerFirstName"
                  name="registerFirstName"
                  placeholder="Enter First Name"
                  spellcheck="false"
                  maxlength="20"
                  autofocus
                  appInputControl="alphabetOnly"
                  [(ngModel)]="userDetails.firstName"
                  [ngClass]="{
                    'input-has-err': registerValidationErrors.firstNameErr
                  }"
                />
                <div
                  class="form-text"
                  *ngIf="registerValidationErrors.firstNameErr"
                >
                  {{ registerValidationErrors.firstNameErrMsg }}
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="mb-4">
                <input
                  type="text"
                  class="form-control"
                  id="registerLastName"
                  name="registerLastName"
                  placeholder="Enter Last Name"
                  spellcheck="false"
                  maxlength="20"
                  appInputControl="alphabetOnly"
                  [(ngModel)]="userDetails.lastName"
                  [ngClass]="{
                    'input-has-err': registerValidationErrors.lastNameErr
                  }"
                />
                <div
                  class="form-text"
                  *ngIf="registerValidationErrors.lastNameErr"
                >
                  {{ registerValidationErrors.lastNameErrMsg }}
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="mb-4">
                <input
                  type="email"
                  class="form-control"
                  id="registerEmail"
                  name="registerEmail"
                  placeholder="Enter Email Address"
                  maxlength="50"
                  appInputControl="noWhiteSpace"
                  [(ngModel)]="userDetails.email"
                  [ngClass]="{
                    'input-has-err': registerValidationErrors.emailErr
                  }"
                />
                <div
                  class="form-text"
                  *ngIf="registerValidationErrors.emailErr"
                >
                  {{ registerValidationErrors.emailErrMsg }}
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="mb-4">
                <input
                  type="number"
                  class="form-control"
                  id="registerPhone"
                  name="registerPhone"
                  placeholder="Enter Phone Number"
                  [(ngModel)]="userDetails.phone"
                  [ngClass]="{
                    'input-has-err': registerValidationErrors.phoneErr
                  }"
                />
                <div
                  class="form-text"
                  *ngIf="registerValidationErrors.phoneErr"
                >
                  {{ registerValidationErrors.phoneErrMsg }}
                </div>
              </div>
            </div>
            <div class="col-lg-12" *ngIf="registerOtpSent">
              <div class="mb-2 mb-lg-4">
                <input
                  type="number"
                  class="form-control"
                  id="registerOTP"
                  name="registerOTP"
                  placeholder="Enter OTP"
                  autocomplete="off"
                  [(ngModel)]="registerOtp"
                  #registerOtpInput
                  [ngClass]="{
                    'input-has-err': registerValidationErrors.otpErr
                  }"
                />
                <div class="form-text" *ngIf="registerValidationErrors.otpErr">
                  {{ registerValidationErrors.otpErrMsg }}
                </div>
              </div>
            </div>
            <div
              class="col-lg-auto d-flex align-items-center justify-content-end mb-4 mb-lg-0"
              *ngIf="registerOtpSent"
            >
              <button
                class="resend-timer"
                [disabled]="registerTimer !== 0"
                (click)="sendRegisterOTP()"
              >
                Resend OTP <span *ngIf="registerTimer !== 0">in </span
                ><span class="time" *ngIf="registerTimer !== 0"
                  >{{ registerTimer }}s</span
                >
              </button>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <div class="button-wrapper">
              <button
                class="button-style2 mb-3"
                type="button"
                (click)="sendRegisterOTP()"
                *ngIf="!registerOtpSent"
              >
                Send OTP
              </button>
              <button
                class="button-style2 mb-3"
                type="button"
                (click)="registerUser()"
                *ngIf="registerOtpSent"
              >
                Sign Up
              </button>
            </div>
          </div>
        </form>
        <!-- <div
          class="guest-login"
          *ngIf="currentMode === 'login'"
          (click)="currentMode = 'register'"
        >
          <a href="javascript:void();">Checkout as a Guest</a>
        </div> -->
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="logoutConfirmation"
  tabindex="-1"
  aria-labelledby="logoutConfirmationLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body pt-0">
        <div class="row">
          <div class="col-lg-12">
            <p class="del-msg">Are you sure you want to logout?</p>
            <div class="modal-btns d-flex justify-content-center">
              <div class="button-wrapper me-4">
                <button
                  class="button-style wd80"
                  role="button"
                  (click)="logoutUser()"
                >
                  Yes
                </button>
              </div>
              <div class="button-wrapper">
                <button
                  class="button-style2"
                  role="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  #closeLogoutModalBtn
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
