<footer>
  <section>
    <div class="container">
      <!-- <div class="why-mo">
        <div class="row">
          <div class="col-lg-12">
            <h2>Why miss olive</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-sm-4 text-mob-center">
            <img src="assets/images/icons/deliver.png" />
            <h5>Fast Shipping</h5>
            <p>We ship all orders within 2-5 <br />business days</p>
          </div>
          <div class="col-lg-4 col-sm-4 text-mob-center">
            <img src="assets/images/icons/shipping.png" />
            <h5>Free shipping</h5>
            <p>For all orders over $100.00</p>
          </div>
          <div class="col-lg-4 col-sm-4 text-mob-center">
            <img src="assets/images/icons/support.png" />
            <h5>Happy to help you</h5>
            <p>Any question? We are happy to help you by E-Mail or Call</p>
          </div>
        </div>
      </div> -->

      <!-- <hr /> -->
      <div class="row">
        <div class="col-lg-3 social">
          <ul>
            <!-- <li><i class="fa-brands fa-facebook-f"></i></li>
            <li><i class="fa-brands fa-twitter"></i></li> -->
            <li>
              <a
                href="https://www.instagram.com/missolive.fashion"
                target="_blank"
                class="socials-icons"
                ><i class="fa-brands fa-instagram"></i
              ></a>
            </li>
            <!-- <li><i class="fa-brands fa-threads"></i></li> -->
            <li>
              <a
                href="https://api.whatsapp.com/send?phone=919324735407"
                target="_blank"
                class="socials-icons"
                ><i class="fa-brands fa-whatsapp"></i
              ></a>
            </li>
          </ul>

          <div class="col-lg-12 d-none d-lg-block pt-2">
            <p class="copyrights">
              Copyright © 2024 Miss Olive <br /><span class="sm-text"
                >Teesta Stores LLP</span
              >
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 offset-lg-1 col-12 mob-btm-space">
          <h6>Connect with us</h6>
          <ul>
            <li>
              <a
                href="mailto:customercare@missolive.fashion"
                style="text-transform: lowercase"
                ><img src="assets/images/shared/mail.png" class="me-1" />
                customercare&#64;missolive.fashion</a
              >
            </li>
            <li>
              <a href="tel:+91 9324735407"
                ><img
                  src="assets/images/shared/call.png"
                  class="call-icon me-1"
                />
                93247 35407</a
              >
            </li>
          </ul>
        </div>
        <div class="col-lg-2 col-6 col-sm-3">
          <ul>
            <li><a href="#">Size Charts</a></li>
            <li><a [routerLink]="['/faq']">FAQ'S</a></li>
            <li><a [routerLink]="['/contact']">contact</a></li>
          </ul>
        </div>
        <div class="col-lg-2 col-6 col-sm-3">
          <ul>
            <li>
              <a [routerLink]="['/terms-and-conditons']">Terms & Conditions</a>
            </li>
            <li><a [routerLink]="['/privacy-policy']">Privacy Policy</a></li>
            <li><a [routerLink]="['/return-policy']">Return Policy</a></li>
            <li><a [routerLink]="['/shipping-policy']">Shipping Policy</a></li>
          </ul>
        </div>
        <div class="col-lg-12 d-block d-lg-none">
          <p class="copyrights">
            Copyright © 2024 Miss Olive <br /><span class="sm-text"
              >Teesta Stores LLP</span
            >
          </p>
        </div>
      </div>
    </div>
  </section>
</footer>
