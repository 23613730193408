import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { exhaustMap, take } from 'rxjs';

export const AuthInterceptor: HttpInterceptorFn = (req, next) => {
  const auth = inject(AuthService);

  if (req.headers.has('token')) {
    return auth.userSubject.pipe(
      take(1),
      exhaustMap((user) => {
        if (user) {
          const modifiedReq = req.clone({
            headers: req.headers.delete('token'),
            setHeaders: {
              Authorization: 'Bearer ' + user.token,
            },
          });
          return next(modifiedReq);
        } else {
          return next(req);
        }
      })
    );
  } else {
    return next(req);
  }
};
