import { Routes } from '@angular/router';
import { authGuard } from './Authentication/services/auth-guard/auth.guard';

export const routes: Routes = [
  //auth
  {
    path: 'login',
    loadComponent: () =>
      import('./Authentication/components/login/login.component').then(
        (c) => c.LoginComponent
      ),
  },
  {
    path: 'register',
    loadComponent: () =>
      import('./Authentication/components/register/register.component').then(
        (c) => c.RegisterComponent
      ),
  },
  {
    path: 'profile',
    loadComponent: () =>
      import('./Authentication/components/profile/profile.component').then(
        (c) => c.ProfileComponent
      ),
    canActivate: [authGuard],
  },

  //shop
  {
    path: 'shop',
    loadComponent: () =>
      import(
        './Product/components/category-listing/category-listing.component'
      ).then((c) => c.CategoryListingComponent),
  },
  {
    path: 'shop/:category-slug',
    loadComponent: () =>
      import(
        './Product/components/subcategory-listing/subcategory-listing.component'
      ).then((c) => c.SubcategoryListingComponent),
  },
  {
    path: 'shop/:category-slug/:subcategory-slug',
    loadComponent: () =>
      import(
        './Product/components/product-listing/product-listing.component'
      ).then((c) => c.ProductListingComponent),
  },
  {
    path: 'shop/:category-slug/:subcategory-slug/:product-slug',
    loadComponent: () =>
      import(
        './Product/components/product-details/product-details.component'
      ).then((c) => c.ProductDetailsComponent),
  },

  //brand pages
  {
    path: 'about',
    loadComponent: () =>
      import('./Brand/components/about/about.component').then(
        (c) => c.AboutComponent
      ),
  },
  {
    path: 'blog',
    loadComponent: () =>
      import('./Brand/components/blog-listing/blog-listing.component').then(
        (c) => c.BlogListingComponent
      ),
  },
  {
    path: 'blog/:slug',
    loadComponent: () =>
      import('./Brand/components/blog-details/blog-details.component').then(
        (c) => c.BlogDetailsComponent
      ),
  },
  {
    path: 'contact',
    loadComponent: () =>
      import('./Brand/components/contact/contact.component').then(
        (c) => c.ContactComponent
      ),
  },
  {
    path: 'faq',
    loadComponent: () =>
      import('./Brand/components/faq/faq.component').then(
        (c) => c.FaqComponent
      ),
  },
  {
    path: 'privacy-policy',
    loadComponent: () =>
      import('./Brand/components/privacy-policy/privacy-policy.component').then(
        (c) => c.PrivacyPolicyComponent
      ),
  },
  {
    path: 'shipping-policy',
    loadComponent: () =>
      import(
        './Brand/components/shipping-policy/shipping-policy.component'
      ).then((c) => c.ShippingPolicyComponent),
  },
  {
    path: 'return-policy',
    loadComponent: () =>
      import('./Brand/components/return-policy/return-policy.component').then(
        (c) => c.ReturnPolicyComponent
      ),
  },
  {
    path: 'store-locator',
    loadComponent: () =>
      import('./Brand/components/store-locator/store-locator.component').then(
        (c) => c.StoreLocatorComponent
      ),
  },
  {
    path: 'terms-and-conditons',
    loadComponent: () =>
      import(
        './Brand/components/terms-and-conditions/terms-and-conditions.component'
      ).then((c) => c.TermsAndConditionsComponent),
  },

  //order
  {
    path: 'cart',
    loadComponent: () =>
      import('./Order/components/cart/cart.component').then(
        (c) => c.CartComponent
      ),
  },
  {
    path: 'wishlist',
    loadComponent: () =>
      import('./Order/components/wishlist/wishlist.component').then(
        (c) => c.WishlistComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: 'checkout',
    loadComponent: () =>
      import('./Order/components/checkout/checkout.component').then(
        (c) => c.CheckoutComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: 'order-faliure',
    loadComponent: () =>
      import('./Order/components/order-faliure/order-faliure.component').then(
        (c) => c.OrderFaliureComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: 'order-success',
    loadComponent: () =>
      import('./Order/components/order-success/order-success.component').then(
        (c) => c.OrderSuccessComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: 'order-details/:order-id',
    loadComponent: () =>
      import('./Order/components/order-details/order-details.component').then(
        (c) => c.OrderDetailsComponent
      ),
    canActivate: [authGuard],
  },

  //shared
  {
    path: '',
    pathMatch: 'full',
    loadComponent: () =>
      import('./Shared/components/home/home.component').then(
        (c) => c.HomeComponent
      ),
  },
  {
    path: 'not-found',
    loadComponent: () =>
      import('./Shared/components/error-page/error-page.component').then(
        (c) => c.ErrorPageComponent
      ),
  },

  { path: '**', redirectTo: 'not-found' },
];
