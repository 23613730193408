import { NgClass, NgIf } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  LoginUserRequest,
  RegisterUserRequest,
  SendOtpRequest,
} from '../../../Authentication/models/auth.models';
import { AuthService } from '../../../Authentication/services/auth/auth.service';
import { ProductService } from '../../../Order/services/product/product.service';
import { InputControlDirective } from '../../directives/input-control/input-control.directive';
import { UtilityService } from '../../services/utility/utility.service';

@Component({
  selector: 'app-pre-checkout-modal',
  standalone: true,
  imports: [NgIf, NgClass, RouterLink, FormsModule, InputControlDirective],
  templateUrl: './pre-checkout-modal.component.html',
  styleUrl: './pre-checkout-modal.component.css',
})
export class PreCheckoutModalComponent {
  @ViewChild('closeLogoutModalBtn') closeBtn: ElementRef;
  currentMode = 'login';

  nameregex = /^[A-Za-z]{1,20}$/;
  phoneRegex = /^[0-9]{10}$/;
  emailRegex = /^\S+@\S+\.\S+$/;
  otpRegex = /^[0-9]{6}$/;

  //=======================Login logic starts=========================================
  @ViewChild('loginOtpInput') loginOtpField: ElementRef;
  @ViewChild('dismissModalBtn') closeModalBtn: ElementRef;

  userPhoneOrEmail = '';
  loginOtpSent = false;
  loginOtp = '';
  loginResendTimer = 60;
  loginTimer: number;
  loginSendOtpSubscription: Subscription;
  loginSubscription: Subscription;

  loginValidationErrors = {
    nameOrEmailErr: false,
    nameOrEmailErrMsg: '',
    otplErr: false,
    otplErrMsg: '',
  };

  constructor(
    private auth: AuthService,
    private product: ProductService,
    private utility: UtilityService,
    private router: Router
  ) {}

  sendLoginOTP() {
    if (!this.validateLoginFields(false)) return;

    const apiData: SendOtpRequest = {
      email: this.emailRegex.test(this.userPhoneOrEmail)
        ? this.userPhoneOrEmail
        : '',
      mobile_number: this.phoneRegex.test(this.userPhoneOrEmail)
        ? this.userPhoneOrEmail
        : '',
      country_code: '91',
      source: 'web',
    };

    this.loginResendTimer = 60;
    this.loginSendOtpSubscription = this.auth.sendLoginOtp(apiData).subscribe({
      next: (res) => {
        if (res.status === 'success') {
          this.loginOtpSent = true;
          this.utility.showSuccessMessage(res.msg);
          setTimeout(() => {
            this.loginOtpField?.nativeElement.focus();
            this.startLoginOtpTimer();
          }, 0);
        } else {
          this.utility.showErrorMessage(res.msg);
        }
      },
      error: (err) => {
        console.log(err);
        this.utility.showErrorMessage(
          'Something went wrong. Please contact support'
        );
      },
    });
  }

  startLoginOtpTimer() {
    //interval is only being set on the browser so window.setInterval is used
    this.loginTimer = window.setInterval(() => {
      this.loginResendTimer--;
      if (this.loginResendTimer === 0) {
        clearTimeout(this.loginTimer);
      }
    }, 1000);
  }

  loginUser() {
    if (!this.validateLoginFields(true)) return;

    const apiData: LoginUserRequest = {
      email: this.emailRegex.test(this.userPhoneOrEmail)
        ? this.userPhoneOrEmail
        : '',
      mobile_number: this.phoneRegex.test(this.userPhoneOrEmail)
        ? this.userPhoneOrEmail
        : '',
      country_code: '91',
      otp: this.loginOtp,
    };

    this.loginSubscription = this.auth.loginUser(apiData).subscribe({
      next: (res) => {
        if (res.status === 'success') {
          this.closeModalBtn.nativeElement.click();
          this.navigateToCheckout();
        } else {
          this.utility.showErrorMessage(res.msg);
        }
      },
      error: (err) => {
        console.log(err);
        this.utility.showErrorMessage(
          'Something went wrong. Please contact support'
        );
      },
    });
  }

  validateLoginFields(validateOtp: boolean): boolean {
    this.loginValidationErrors.nameOrEmailErr = false;
    this.loginValidationErrors.otplErr = false;

    let noErrors = true;

    if (
      !this.phoneRegex.test(this.userPhoneOrEmail) &&
      !this.emailRegex.test(this.userPhoneOrEmail)
    ) {
      this.loginValidationErrors.nameOrEmailErr = true;
      this.loginValidationErrors.nameOrEmailErrMsg =
        'Please enter a valid phone number or email';
      noErrors = false;
    }

    if (
      this.loginOtpSent &&
      validateOtp &&
      !this.otpRegex.test(this.loginOtp)
    ) {
      this.loginValidationErrors.otplErr = true;
      this.loginValidationErrors.otplErrMsg = 'Please enter a valid OTP';
      noErrors = false;
    }

    return noErrors;
  }
  //=======================Login logic ends=========================================

  //=======================Register logic starts=========================================
  @ViewChild('registerOtpInput') registerOtpField: ElementRef;

  userDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  };
  registerOtpSent = false;
  registerOtp = '';
  registerTimer = 60;
  timer: number;
  sendRegisterOtpSubscription: Subscription;
  registerSubscription: Subscription;

  registerValidationErrors = {
    firstNameErr: false,
    firstNameErrMsg: '',
    lastNameErr: false,
    lastNameErrMsg: '',
    emailErr: false,
    emailErrMsg: '',
    phoneErr: false,
    phoneErrMsg: '',
    otpErr: false,
    otpErrMsg: '',
  };

  sendRegisterOTP() {
    if (!this.validateRegisterFields(false)) return;

    const apiData: SendOtpRequest = {
      email: this.userDetails.email,
      mobile_number: this.userDetails.phone,
      country_code: '91',
      source: 'web',
    };

    this.registerTimer = 60;
    this.sendRegisterOtpSubscription = this.auth
      .sendRegisterOtp(apiData)
      .subscribe({
        next: (res) => {
          if (res.status === 'success') {
            this.registerOtpSent = true;
            this.utility.showSuccessMessage(res.msg);
            setTimeout(() => {
              this.registerOtpField?.nativeElement.focus();
              this.startOtpTimer();
            }, 0);
          } else {
            this.utility.showErrorMessage(res.msg);
          }
        },
        error: (err) => {
          console.log(err);
          this.utility.showErrorMessage(
            'Something went wrong. Please contact support'
          );
        },
      });
  }

  startOtpTimer() {
    //interval is only being set on the browser so window.setInterval is used
    this.timer = window.setInterval(() => {
      this.registerTimer--;
      if (this.registerTimer === 0) {
        clearTimeout(this.timer);
      }
    }, 1000);
  }

  registerUser() {
    if (!this.validateRegisterFields(true)) return;

    const apiData: RegisterUserRequest = {
      first_name: this.userDetails.firstName,
      last_name: this.userDetails.lastName,
      email: this.userDetails.email,
      mobile_number: this.userDetails.phone,
      otp: this.registerOtp,
      country_code: '91',
      source: 'web',
      register_via: 'guest',
    };

    this.registerSubscription = this.auth.registerNewUser(apiData).subscribe({
      next: (res) => {
        if (res.status === 'success') {
          this.closeModalBtn.nativeElement.click();
          this.navigateToCheckout();
        } else {
          this.utility.showErrorMessage(res.msg);
        }
      },
      error: (err) => {
        console.log(err);
        this.utility.showErrorMessage(
          'Something went wrong. Please contact support'
        );
      },
    });
  }

  validateRegisterFields(validateOtp: boolean): boolean {
    this.registerValidationErrors.firstNameErr = false;
    this.registerValidationErrors.lastNameErr = false;
    this.registerValidationErrors.emailErr = false;
    this.registerValidationErrors.phoneErr = false;
    this.registerValidationErrors.otpErr = false;

    let noErrors = true;

    if (!this.nameregex.test(this.userDetails.firstName)) {
      this.registerValidationErrors.firstNameErr = true;
      this.registerValidationErrors.firstNameErrMsg =
        'Please enter a valid first name';
      noErrors = false;
    }
    if (!this.nameregex.test(this.userDetails.lastName)) {
      this.registerValidationErrors.lastNameErr = true;
      this.registerValidationErrors.lastNameErrMsg =
        'Please enter a valid last name';
      noErrors = false;
    }
    if (!this.emailRegex.test(this.userDetails.email)) {
      this.registerValidationErrors.emailErr = true;
      this.registerValidationErrors.emailErrMsg = 'Please enter a valid email';
      noErrors = false;
    }
    if (!this.phoneRegex.test(this.userDetails.phone)) {
      this.registerValidationErrors.phoneErr = true;
      this.registerValidationErrors.phoneErrMsg =
        'Please enter a valid phone number';
      noErrors = false;
    }
    if (
      this.registerOtpSent &&
      validateOtp &&
      !this.otpRegex.test(this.registerOtp)
    ) {
      this.registerValidationErrors.otpErr = true;
      this.registerValidationErrors.otpErrMsg = 'Please enter a valid OTP';
      noErrors = false;
    }

    return noErrors;
  }

  navigateToCheckout() {
    if (this.product.checkoutMode === 'cart') {
      this.router.navigate(['/checkout']);
    } else {
      this.router.navigate(['/checkout'], {
        queryParams: { mode: 'buy-now' },
      });
    }
  }

  logoutUser() {
    this.auth.logoutUser('manual');
    this.closeBtn.nativeElement.click();
  }

  ngOnDestroy(): void {
    if (this.loginTimer) clearInterval(this.loginTimer);
    this.loginSendOtpSubscription?.unsubscribe();
    this.loginSubscription?.unsubscribe();

    if (this.timer) clearInterval(this.timer);
    this.sendRegisterOtpSubscription?.unsubscribe();
    this.registerSubscription?.unsubscribe();
  }
}
