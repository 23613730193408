<div class="nav-header d-none d-lg-block">
  <!-- <div class="container">
    <div class="row">
      <div class="col-lg-10 col-md-9 cc"></div>
      <div class="col-lg-2 col-md-3 col-6 cc-mob">
        <div class="col-lg-6 float-right">
          <div class="select-dropdown curr">
            <select>
              <option value="usd">USD</option>
              <option value="inr">INR</option>
            </select>
          </div>
        </div>
        <div class="col-lg-6 float-right">
          <div class="select-dropdown">
            <select>
              <option value="english">English</option>
              <option value="arabic">Arabic</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</div>
<div class="nav-header d-lg-none">
  <div class="container">
    <div class="row align-items-center justify-content-end">
      <!-- <div class="col-6">
        <div class="row">
          <div class="col-6 col-sm-4">
            <div class="select-dropdown curr">
              <select>
                <option value="usd">USD</option>
                <option value="inr">INR</option>
              </select>
            </div>
          </div>
          <div class="col-6 col-sm-4">
            <div class="select-dropdown">
              <select>
                <option value="english">English</option>
                <option value="arabic">Arabic</option>
              </select>
            </div>
          </div>
        </div>
      </div> -->
      <div class="col-6">
        <div class="header-item item-right">
          <div class="mob-icons d-none">
            <div class="d-flex align-items-lg-center position-relative">
              <a
                href="#searchmodal"
                data-bs-toggle="modal"
                data-bs-target="#searchmodal"
              >
                <img src="assets/images/shared/search.png" />
              </a>
            </div>
            <div
              class="d-flex align-items-lg-center position-relative"
              *ngIf="isLoggedIn"
            >
              <span class="counter" *ngIf="wishlistProductCount">{{
                wishlistProductCount
              }}</span>
              <a [routerLink]="['/wishlist']"
                ><img src="assets/images/shared/wishlist.png"
              /></a>
            </div>
            <div class="d-flex align-items-lg-center position-relative">
              <span class="counter" *ngIf="cartProductCount">{{
                cartProductCount
              }}</span>
              <a [routerLink]="['/cart']"
                ><img src="assets/images/shared/cart.png"
              /></a>
            </div>
            <div
              class="d-flex align-items-lg-center menu-item-has-children custom-pad position-relative"
            >
              <a
                (click)="profileMenuOpen = !profileMenuOpen"
                style="cursor: pointer"
                ><img src="assets/images/shared/user.png"
              /></a>
              <ul class="profile-menu" *ngIf="profileMenuOpen" @fadeAnimation>
                <li *ngIf="isLoggedIn">
                  <a [routerLink]="['/profile']">Profile</a>
                </li>
                <li *ngIf="isLoggedIn">
                  <a [routerLink]="['/profile']" [fragment]="'order'"
                    >My Orders</a
                  >
                </li>
                <li *ngIf="isLoggedIn">
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#logoutConfirmation"
                  >
                    Logout
                  </button>
                </li>
                <li *ngIf="!isLoggedIn">
                  <a [routerLink]="['/login']">Login</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<header class="header" id="mo-Header" #moHeader>
  <div class="container">
    <div class="row v-center">
      <div class="header-item item-left">
        <a class="navbar-brand" [routerLink]="['/']">
          <img src="assets/images/shared/logo.png" class="img-fluid logo" />
        </a>
      </div>
      <!-- menu start here -->
      <div class="header-item item-center">
        <div
          class="menu-overlay"
          [ngClass]="{ active: mobileMenuOpen }"
          (click)="mobileMenuOpen = false"
        ></div>
        <nav class="menu" [ngClass]="{ active: mobileMenuOpen }">
          <div
            class="mobile-menu-head"
            [ngClass]="{ active: mobileShopMenuOpen }"
          >
            <div class="go-back" (click)="mobileShopMenuOpen = false">
              <i class="fa fa-angle-left"></i>
            </div>
            <div class="current-menu-title">
              <span *ngIf="mobileShopMenuOpen">Shop</span>
            </div>
            <div class="mobile-menu-close" (click)="mobileMenuOpen = false">
              &times;
            </div>
          </div>
          <ul class="menu-main">
            <li>
              <a
                [routerLink]="['/']"
                [routerLinkActive]="['tc-pink']"
                [routerLinkActiveOptions]="{ exact: true }"
                >Home</a
              >
            </li>
            <li>
              <a [routerLink]="['/about']" [routerLinkActive]="['tc-pink']"
                >About</a
              >
            </li>
            <!-- <li class="menu-item-has-children">
              <a
                >Shop <i class="fa fa-angle-down d-none d-lg-inline-block"></i
              ></a>
              <i
                class="fa fa-angle-right d-lg-none"
                (click)="mobileShopMenuOpen = true"
              ></i>
              <div
                class="sub-menu mega-menu mega-menu-column-4"
                [ngClass]="{ active: mobileShopMenuOpen }"
                [style.animation]="
                  mobileShopMenuOpen
                    ? 'slideLeft 0.5s ease forwards'
                    : 'slideRight 0.5s ease forwards'
                "
              >
                <div class="list-item" *ngFor="let cat of shopList">
                  <a [routerLink]="['/shop', cat.slug_value]" class="title">{{
                    cat.main_category_name
                  }}</a>
                  <ul>
                    <li *ngFor="let subcat of cat.sub_category_data">
                      <a
                        [routerLink]="[
                          '/shop',
                          cat.slug_value,
                          subcat.slug_value
                        ]"
                        >{{ subcat.sub_category_name | titlecase }}</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </li> -->

            <!-- -------------------------------------------------  -->
            <li class="menu-item-has-children" (click)="mobileShopMenuOpen = true">
              <a
                >Shop <i class="fa fa-angle-down d-none d-lg-inline-block"></i
              ></a>
              <i
                class="fa fa-angle-right d-lg-none"
                
              ></i>
              <div
                class="sub-menu mega-menu mega-menu-column-4"
                [ngClass]="{ active: mobileShopMenuOpen }"
                [style.animation]="
                  mobileShopMenuOpen
                    ? 'slideLeft 0.5s ease forwards'
                    : 'slideRight 0.5s ease forwards'
                "
              >
                <div class="list-item" *ngFor="let cat of shopList">
                  <a [routerLink]="['/shop', cat.slug_value]" class="title">{{
                    cat.main_category_name
                  }}</a>
                  <ul>
                    <li *ngFor="let subcat of cat.sub_category_data">
                      <a
                        [routerLink]="[
                          '/shop',
                          cat.slug_value,
                          subcat.slug_value
                        ]"
                        >{{ subcat.sub_category_name | titlecase }}</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <!-- -------------------------------------------------- -->

            <li>
              <a
                [routerLink]="['/store-locator']"
                [routerLinkActive]="['tc-pink']"
                >Store Locator</a
              >
            </li>
            <!-- <li>
              <a [routerLink]="['/contact']" [routerLinkActive]="['tc-pink']"
                >Contact</a
              >
            </li>
            <li>
              <a [routerLink]="['/blog']" [routerLinkActive]="['tc-pink']"
                >Blogs</a
              >
            </li> -->
          </ul>
        </nav>
      </div>
      <!-- menu end here -->
      <div class="header-item item-right">
        <div class="">
          <div class="mob-icons">
            <div class="d-flex align-items-lg-center mt-lg-0 position-relative">
              <a
                href="#searchmodal"
                data-bs-toggle="modal"
                data-bs-target="#searchmodal"
              >
                <img src="assets/images/shared/search.png" />
              </a>
            </div>
            <div
              class="d-flex align-items-lg-center position-relative"
              *ngIf="isLoggedIn"
            >
              <span class="counter" *ngIf="wishlistProductCount">{{
                wishlistProductCount
              }}</span>
              <a [routerLink]="['/wishlist']"
                ><img src="assets/images/shared/wishlist.png"
              /></a>
            </div>
            <div class="d-flex align-items-lg-center mt-lg-0 position-relative">
              <span class="counter" *ngIf="cartProductCount">{{
                cartProductCount
              }}</span>
              <a [routerLink]="['/cart']"
                ><img src="assets/images/shared/cart.png"
              /></a>
            </div>
            <div
              class="d-flex align-items-lg-center mt-lg-0 menu-item-has-children custom-pad position-relative"
            >
              <a
                (click)="profileMenuOpen = !profileMenuOpen"
                style="cursor: pointer"
                ><img src="assets/images/shared/user.png"
              /></a>
              <ul class="profile-menu" *ngIf="profileMenuOpen" @fadeAnimation>
                <li *ngIf="isLoggedIn">
                  <a [routerLink]="['/profile']">Profile</a>
                </li>
                <li *ngIf="isLoggedIn">
                  <a [routerLink]="['/profile']" [fragment]="'order'"
                    >My Orders</a
                  >
                </li>
                <li *ngIf="isLoggedIn">
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#logoutConfirmation"
                  >
                    Logout
                  </button>
                </li>
                <li *ngIf="!isLoggedIn">
                  <a [routerLink]="['/login']">Login</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- mobile menu trigger -->
        <div
          class="mobile-menu-trigger"
          (click)="mobileMenuOpen = true; mobileShopMenuOpen = false"
        >
          <span></span>
        </div>
      </div>
    </div>
  </div>
</header>

<div
  class="modal fade"
  id="searchmodal"
  tabindex="-1"
  aria-labelledby="searchmodalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="searchmodalLabel">
          What are you Looking for?
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          #modalCloseBtn
        ></button>
      </div>
      <div class="modal-body">
        <div class="input-group position-relative">
          <input
            type="text"
            class="form-control"
            placeholder="Search"
            [(ngModel)]="searchQuery"
            (keyup)="globalSearch()"
          />
          <button class="btn btn-success" type="submit">
            <i class="fa-solid fa-magnifying-glass"></i>
          </button>
          <ul *ngIf="searchQuery && globalSearchResults.length">
            <li *ngFor="let product of globalSearchResults">
              <a
                [routerLink]="[
                  '/shop',
                  product.main_cat_slug,
                  product.sub_cat_slug,
                  product.slug_value
                ]"
                (click)="closeSearchModal()"
              >
                <div class="img-wrapper">
                  <img
                    [src]="product.thumbnail_image"
                    [alt]="product.thumbnail_image_alt_text"
                  />
                </div>
                {{ product.productname }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
